/*
* FIXME:  This whole component will need to be refactored using
*         the cord connector package "@yellowtail-frontend/cord-connector".
*         The interfaces will have to match the ones exported from the Cord Connector.
*         This will most likely require changes to every API request made to the Cord Connector,
*         but will be a blocker when going to production.
* */

// Axios
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse, Method} from 'axios';

// Middleware
import {UrlHelper} from '../urlHelper/UrlHelper';
import {APITypes} from '../enums/Enums';

// Interfaces
import * as INTERFACES from './ServiceHelperInterfaces';

// Constants
const TIMEOUT = 10000;

// Handlers
const getHttpClient = (method: string): AxiosInstance => {
  const defaultOptions: AxiosRequestConfig = populateDefaults.options();
  const jwtToken = localStorage.getItem('jwt_token');
  const instance: AxiosInstance = axios.create(defaultOptions);

  //Default Options and headers
  if (method) {
    defaultOptions['method'] = Object.keys(method) as unknown as Method;
  }

  //Interceptors
  instance.interceptors.request.use((config) => {
    config.headers!.Authorization = jwtToken?.toString() ?? '';

    return config;
  });

  return instance;
};

const populateDefaults = {
  //Default axios options
  options: (): AxiosRequestConfig => {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type,Content-Disposition,AppIdentity,CheckString',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        'Access-Control-Allow-Methods': 'OPTIONS,GET,POST,PUT,DELETE',
        'Access-Control-Allow-Origin': '*',
      },
    };
  },
};

const handleUrl = (api: string): string | undefined => {
  if (!api || api === '') return '';

  let result: string | undefined;

  switch (api) {
    case APITypes.IAM:
      result = process.env['REACT_APP_IAM_API_URL'];
      break;
    case APITypes.CF:
      result = process.env['REACT_APP_CF_URL'];
      break;
    case APITypes.RULECUBE:
      result = process.env['REACT_APP_WRAPPER_URL_RULECUBE'];
      break;
    case APITypes.INVITATIONS:
      result = process.env['REACT_APP_WRAPPER_URL_INVITATIONS'];
      break;
    case APITypes.PSD2:
      result = process.env['REACT_APP_PSD2_URL'];
      break;
    default:
      result = '';
  }

  return result;
};

export const ServiceHelper = {
  // Used to retrieve documents from the API
  get: (props: INTERFACES.ServiceGETInterface): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const instance: AxiosInstance = getHttpClient('GET');
        const params: string = UrlHelper(props?.params ?? {});
        const url: string = handleUrl(props?.api ?? '') + props.url + params ?? '';
        let result: AxiosResponse;

        instance
          .get(url, {timeout: Number(props?.timeout) ?? TIMEOUT})
          .then(response => {
            result = response ?? [];
          })
          .catch(error => {
            reject(error?.response?.data?.error ?? error);
            if (error?.response?.status === 401) return;

            console.error('Error ServiceHelper: GET - ', error);
          })
          .finally(() => {
            resolve(result);
          });
      } catch (error) {
        console.error('Error ServiceHelper: GET - ', error);
        reject(error);
      }
    });
  },

  // Used to insert a new document
  post: (props: INTERFACES.ServicePOSTInterface): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const instance: AxiosInstance = getHttpClient('POST');
        const url: string = handleUrl(props?.api ?? '') + props.url;
        let result: AxiosResponse;

        instance
          .post(url, props?.body ?? {}, {timeout: Number(props?.timeout) ?? TIMEOUT})
          .then(response => {
            result = response ?? undefined;
          })
          .catch(error => {
            reject(error);
          })
          .finally(() => {
            resolve(result);
          });
      } catch (error) {
        reject(error);
      }
    });
  },

  // Used to update an existing document
  put: (props: INTERFACES.ServicePUTInterface): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        const instance: AxiosInstance = getHttpClient('PUT');
        const url: string = handleUrl(props?.api ?? '') + props.url;
        let result: Array<Record<string, unknown>> = [];

        instance
          .put(url, props?.body ?? {}, {timeout: Number(props?.timeout) ?? TIMEOUT})
          .then(response => {
            result = response?.data?.result ?? [];
          })
          .catch(error => {
            reject(error?.response?.data?.error ?? error);
            if (error?.response?.data?.status === 401) return;

            console.error('Error ServiceHelper: PATCH - ', error);
          })
          .finally(() => {
            resolve(result);
          });
      } catch (error) {
        console.error('Error ServiceHelper: PATCH - ', error);
        reject(error);
      }
    });
  },

  // Used to delete and existing document
  delete: (props: INTERFACES.ServiceDELETEInterface): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        if (!props.id) {
          reject('"id" parameter cannot be empty');
          return;
        }

        const instance: AxiosInstance = getHttpClient('DELETE');
        const url = `${handleUrl(props?.api ?? '')}${props?.url ?? ''}/${props?.id}`;
        let result: Array<Record<string, unknown>> = [];

        instance
          .delete(url, {timeout: Number(props?.timeout) ?? TIMEOUT})
          .then(response => {
            result = response?.data?.result ?? [];
          })
          .catch(error => {
            reject(error?.response?.data?.error ?? error);
            if (error?.response?.data?.status === 401) return;

            console.error('Error ServiceHelper: DELETE - ', error);
          })
          .finally(() => {
            resolve(result);
          });
      } catch (error) {
        console.error('Error ServiceHelper: DELETE - ', error);
        reject(error);
      }
    });
  },
};
