export const Themes = Object.freeze({
  DEFAULT: 'default',
});

export const APITypes = Object.freeze({
  IAM: 'iam',
  CF: 'cf',
  RULECUBE: 'rulecube',
  INVITATIONS: 'invitations',
  PSD2: 'psd2'
});

export enum OwnerTypes {
  Client = 'Client',
  Partner = 'Partner',
  Child = 'Child'
}

export const InputTypes = Object.freeze({
  CHECKBOX: 'checkbox',
  EMAIL: 'email',
  HIDDEN: 'hidden',
  NUMBER: 'number',
  PASSWORD: 'password',
  RADIO: 'radio',
  TEL: 'tel',
  TEXT: 'text',
  URL: 'url',
});

export enum Pages {
  Download = 'download',
  Thanks = 'bedankt',
  Real_Picture = 'Echte plaatje',
  Estimate = 'Schatting',
  Accounts = 'Accounts',
  Nibud = 'Nibud',
}

export enum GlobalState { /*Update global state hook as well*/
  ACTIVE_PAGE = 'activePages',
  HAS_PARTNER = 'hasPartner',
}
